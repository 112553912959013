<template>
  <div class="footer">
    <div class="beian">
      Copyright 2014-{{ year }} 杭州雪意科技有限公司 All rights reserved<i
      ></i>Designed by HopeBeauty |
      <a type="_brand" href=" http://beian.miit.gov.cn">浙ICP备15026216-2</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ForTheRecord',
  data() {
    return {
      year: '',
    }
  },
  created() {
    this.getNowYears()
  },
  methods: {
    getNowYears() {
      let datatime = new Date()
      this.year = datatime.getFullYear()
    },
  },
}
</script>
<style lang="scss" scoped>
.footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  .beian {
    display: inline-block;
    padding: 10px;
    text-align: center;
    a {
      color: black;
    }
  }
}
</style>
