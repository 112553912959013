<template>
  <div id="login" :style="{ backgroundImage: 'url(' + loginBG + ')' }">
    <div class="login_warp">
      <h1 class="login_title">雪意OA管理系统</h1>
      <el-form
        :model="loginForm"
        status-icon
        ref="loginFormRef"
        size="medium "
        class="login_form"
      >
        <el-form-item prop="username" class="item_from">
          <el-input
            id="username"
            type="text"
            v-model="loginForm.username"
            autocomplete="off"
            placeholder="用户名/手机号/邮箱"
          ></el-input>
        </el-form-item>

        <el-form-item prop="password" class="item_from">
          <el-input
            id="password"
            type="password"
            v-model="loginForm.password"
            autocomplete="off"
            minlength="6"
            maxlength="20"
            placeholder="密码"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>

        <el-form-item class="item_from">
          <el-checkbox label="是否记住密码" :v-model="isRemenber"></el-checkbox>
        </el-form-item>

        <el-form-item class="item_from">
          <el-button @click="login" class="login_btn block">登录</el-button>
        </el-form-item>

        <el-form-item class="item_from">
          <div class="forget_link">
            <el-link :underline="false" type="primary">忘记密码</el-link>
            <el-link :underline="false" type="primary"
              >没有账号?点击注册</el-link
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
    <BackToTop />
  </div>
</template>
<script>
import loginBG from '@/assets/login/oa_login_bg.png'
import BackToTop from '@/components/ForTheRecord/index.vue'
export default {
  name: 'login',
  components: {
    BackToTop,
  },
  data() {
    return {
      loginBG: loginBG + '?' + +new Date(),
      isRemenber: false,
      loginForm: {
        username: process.env.VUE_APP_USERNAME ?? '',
        password: process.env.VUE_APP_PWD ?? '',
      },
      redirect: undefined,
      otherQuery: {},
    }
  },
  created() {},
  methods: {
    login() {
      this.$store
        .dispatch('user/login', this.loginForm)
        .then(() => {
          this.$router.push({
            path: this.redirect || '/',
            query: this.otherQuery,
          })
        })
        .catch((err) => {
          console.log('error')
        })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
#login {
  position: absolute;
  height: 100%;
  width: 100%;
  //background-image: url('@/assets/login/oa_login_bg.png');
}
.login_warp {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 644px;
  height: 563px;
  background: #ffffff;
  box-shadow: 0px 7px 25px 2px rgba(180, 183, 202, 0.35);
  border-radius: 20px;
  border-top: 1px transparent solid;
}
.login_title {
  font-size: 38px;
  font-weight: bold;
  color: #333539;
  line-height: 47px;
  text-align: center;
  margin-top: 52px;
}
.login_form {
  margin-top: 70px;
  .item_from {
    margin: 0 110px;
    margin-bottom: 26px;
  }
  .login_btn {
    height: 60px;
    background: #8c50ff;
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
  }
  .block {
    display: block;
    width: 100%;
  }
  .forget_link {
    display: flex;
    justify-content: space-between;
  }
}

::v-deep .el-input__inner {
  height: 48px;
}

/*
  复选框中checkbox样式更改
*/
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #8c50ff;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #8c50ff;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #8c50ff;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #8c50ff;
}

::v-deep .el-link.el-link--primary {
  color: #8c50ff;
}
</style>
